




















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderStatuses from "@/constants/orderStatuses";
import orderTelesaleStatuses from "@/constants/orderTelesaleStatuses";
import orderLocationStatuses from "@/constants/orderLocationStatuses";
import orderPickUpStatuses from "@/constants/orderPickUpStatuses";
import orderDataSources from "@/constants/orderDataSources";
import orderShippingStatuses from "@/constants/orderShippingStatuses";
import orderCustomerRefusedStatuses from "@/constants/orderCustomerRefusedStatuses";
import orderShipmentMethods from "@/constants/orderShipmentMethods";
import userManager from "@/services/userManager";
import DataContainer from "@vuetifyx/common/DataContainer";
import ProductOrCombo from "./partials/ProductOrCombo.vue";
import OrderHistory from "./partials/OrderHistory.vue";
import orderDtStatuses from "@/constants/orderDtStatuses";
import productComboProductItemRoles from "@/constants/productComboProductItemRoles";
import WholesaleBillImagesVue from "./partials/WholesaleBillImages.vue";
import { displayItemsWithSku } from "@/utils/marketItemDisplay";
import OrderPricingVue from "./partials/OrderPricing.vue";
import ProductRowItemsTable from "./index/partials/ProductRowItemsTable.vue";
import moment from "moment";
import orderTelesaleStatusesEn from "@/constants/orderTelesaleStatusesEn";

export default Vue.extend({
  components: {
    ProductOrCombo,
  },
  data() {
    return {
      self: this,
      userManager,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market, setting) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          id: `marketDetails_orders_index_${market.code}`,
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            hTime: {
              text: "Ngày hủy",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            returnTime: userManager.checkRole(["kt", "vd", "vdl"]) && {
              text: "Ngày return",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            dxkTime: {
              text: "Ngày xuất kho",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: !market.wholesale && {
              text: "Sản phẩm",
            },
            singlePrice: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Đơn giá",
                hidden: !market.tmdt,
                options: {
                  preTransform(_, item) {
                    let value = (item.product && item.product.localPrice) || (item.productCombo && item.productCombo.overriddenLocalPrice) || item.productCombo?.localPrice;
                    if (isNaN(value)) {
                      value = 0;
                    }
                    return value;
                  },
                  filter: "currency",
                  transform(value) {
                    return `${value} ${market.currency}`;
                  },
                },
              },
            count: !market.wholesale && {
              text: "Số lượng",
              sortable: true,
              hidden: !market.tmdt,
            },
            totalPrice: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Tổng giá",
                options: {
                  preTransform(_, item) {
                    let value = 0;
                    value += (item.products || []).reduce((total, p) => total + (p.localPrice || 0) * (p.orderCount || 1), 0);
                    value += (item.productCombos || []).reduce((total, pc) => total + (pc.overriddenLocalPrice || pc.localPrice || 0) * (pc.orderCount || 1), 0);
                    if (isNaN(value)) {
                      value = 0;
                    }
                    return value * (item.count || 1);
                  },
                  filter: "currency",
                  transform(value) {
                    return `${value} ${market.currency}`;
                  },
                },
              },
            deltaTotalPrice: !userManager.checkRole(["content", "design"], true) &&
              market.code.includes("telesale") && {
                text: "Chênh lệch tổng giá telesale",
                options: {
                  preTransform(_, item) {
                    let value = 0;
                    {
                      value += (item.products || []).reduce((total, p) => total + (p.localPrice || 0) * (p.orderCount || 1), 0);
                      value += (item.productCombos || []).reduce((total, pc) => total + (pc.overriddenLocalPrice || pc.localPrice || 0) * (pc.orderCount || 1), 0);
                      if (isNaN(value)) {
                        value = 0;
                      }
                    }
                    let beforeValue = 0;
                    {
                      beforeValue += (item.products || []).reduce((total, p) => total + (p.localPrice || 0) * (p.orderCount || 1), 0);
                      beforeValue += (item.productCombos || []).reduce((total, pc) => total + (pc.localPrice || 0) * (pc.orderCount || 1), 0);
                      if (isNaN(beforeValue)) {
                        beforeValue = 0;
                      }
                    }
                    return (value - beforeValue) * item.count;
                  },
                  filter: "currency",
                  transform(value) {
                    return value && `${value} ${market.currency}`;
                  },
                },
              },
            telesaleConfirmed: userManager.checkRole(["kt", "vd", "vdl"]) &&
              market.code.includes("telesale") && {
                text: "Telesale Confirmed",
                options: {
                  boolean: true,
                },
              },
            productItems: market.wholesale && {
              text: "Sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.productItems?.map((pi) => `${pi.count || 1} x ${pi.product.sku}`));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            reOrderCount: userManager.checkRole(["kt"]) &&
              !market.tmdt &&
              !market.wholesale && {
                text: "Số lần re-order",
              },
            wholesaleProductItemProductGroup: market.wholesale && {
              text: "Nhóm sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.productItems?.map((pi) => pi.wholesaleProductGroup?.sku || "N/A"));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            whoelsaleRoles: market.wholesale && {
              text: "Loại sản phẩm",
              options: {
                labels(_, item) {
                  return [""].concat(item.productItems?.map((pi) => productComboProductItemRoles[pi.wholesaleRole]));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleTotalPrice: market.wholesale && {
              text: "Tổng giá trước discount",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.productItems.reduce((total, pi) => total + pi.wholesaleTotalPrice, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.productItems?.map((pi) => Vue.filter("currency")(pi.wholesaleTotalPrice)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleDiscountPercentage: market.wholesale && {
              text: "% Discount",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.productItems.reduce((total, pi) => total + pi.wholesaleTotalPrice * pi.wholesaleDiscountPercentage, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.productItems?.map((pi) => `${pi.wholesaleDiscountPercentage * 100}%`));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleAfterDiscount: market.wholesale && {
              text: "Tổng giá sau Discount",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.productItems.reduce((total, pi) => total + pi.wholesaleTotalPrice - pi.wholesaleDiscountPercentage * pi.wholesaleTotalPrice, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.productItems?.map((pi) => Vue.filter("currency")(pi.wholesaleTotalPrice - pi.wholesaleDiscountPercentage * pi.wholesaleTotalPrice)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleReceivedAmount: market.wholesale && {
              text: "Số tiền thực nhận",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.productItems.reduce((total, pi) => total + pi.wholesaleReceivedAmount, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.productItems?.map((pi) => Vue.filter("currency")(pi.wholesaleReceivedAmount)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleCustomerBalance: market.wholesale && {
              text: "Balance khách trả",
              options: {
                labels(_, item) {
                  return [
                    {
                      value: Vue.filter("currency")(item.productItems.reduce((total, pi) => total + pi.wholesaleTotalPrice - pi.wholesaleDiscountPercentage * pi.wholesaleTotalPrice - pi.wholesaleReceivedAmount, 0)),
                      attrs: {
                        outlined: true,
                      },
                    },
                  ].concat(item.productItems?.map((pi) => Vue.filter("currency")(pi.wholesaleTotalPrice - pi.wholesaleDiscountPercentage * pi.wholesaleTotalPrice - pi.wholesaleReceivedAmount)));
                },
                labelsSeparatedByNewLine: true,
              },
            },
            wholesaleShippingCost: market.wholesale && {
              text: "Phí ship",
              options: {
                filter: "currency",
              },
            },
            wholesaleExchangeRate: market.wholesale &&
              userManager.checkRole(["kt"]) && {
                text: "Tỷ giá thực",
                options: {
                  filter: "currency",
                },
              },
            wholesaleOrderId: market.wholesale && {
              text: "Mã đơn sỉ",
            },
            wholesalePaidTime: market.wholesale && {
              text: "Ngày KH chuyển tiền",
              options: {
                filter: "dateTime",
              },
            },
            wholesaleExpectedDeliverTime: market.wholesale && {
              text: "Thời gian nhận hàng mong muốn",
              options: {
                filter: "dateTime",
              },
            },
            wholesaleConfirmed: market.wholesale && {
              text: "Kế toán Xác nhận",
              options: {
                boolean: true,
              },
            },
            wholesaleBankAccountName: market.wholesale && {
              text: "Tên Tài khoản",
            },
            wholesaleBankAccountNumber: market.wholesale && {
              text: "Số tài khoản",
            },
            wholesaleBankName: market.wholesale && {
              text: "Tên Ngân hàng",
            },
            exchangeRate: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Tỷ giá",
              hidden: true,
              sortable: true,
              options: {
                subProp: "market.exchangeRate",
                filter: "currency",
              },
            },
            status: {
              text: "Trạng thái",
              sortable: true,
              options: {
                label(value) {
                  return orderStatuses[value || ""];
                },
              },
            },
            returned: userManager.checkRole(["kt", "vd", "vdl"]) && {
              text: "Đơn thực tế về kho",
              options: {
                boolean: true,
              },
            },
            customerRefusedStatus: !userManager.checkRole(["content", "design"], true) && {
              text: "TT Hủy đơn",
              sortable: true,
              hidden: market.tmdt,
              options: {
                label(value) {
                  return orderCustomerRefusedStatuses[value || ""];
                },
              },
            },
            dtStatus: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "TT Hủy đơn trùng",
                sortable: true,
                hidden: market.tmdt,
                options: {
                  label(value) {
                    return orderDtStatuses[value || ""];
                  },
                },
              },
            incomeItemAlerted: market.tmdt && {
              text: "Cảnh báo thành phần income",
              options: {
                boolean: true,
              },
            },
            customerCode: !userManager.checkRole(["content", "design"], true) &&
              market.wholesale && {
                text: "Mã KH",
                sortable: true,
              },
            customerName: !userManager.checkRole(["content", "design"], true) && {
              text: "Tên",
              sortable: true,
            },
            customerPhone: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) && {
              text: "SĐT",
              sortable: true,
            },
            customerIp: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "IP",
                hidden: market.tmdt,
              },
            customerIpCountry: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "IP country",
                hidden: market.tmdt,
              },
            customerIpCountryInvalid: !userManager.checkRole(["mkt", "content", "design"], true) &&
              !market.wholesale &&
              !market.wholesale && {
                text: "IP khác quốc gia",
                options: {
                  boolean: true,
                },
                hidden: market.tmdt,
              },
            customerIpCoincide: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "IP trùng so với tất cả đơn hàng",
                options: {
                  boolean: true,
                },
                hidden: market.tmdt,
              },
            customerIpCoincideRecent: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "IP trùng gần đây",
                options: {
                  boolean: true,
                },
                hidden: market.tmdt,
              },
            customerEmail: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Email",
                sortable: true,
                hidden: market.tmdt,
              },
            customerMessage: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Lời nhắn",
                sortable: true,
                hidden: market.tmdt,
                options: {
                  attrs: {
                    style: "max-width: 200px; display: inline-block",
                  },
                },
              },
            customerState: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) &&
              !market.wholesale && {
                text: "State",
                hidden: market.tmdt,
              },
            customerCity: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) &&
              !market.wholesale && {
                text: "City",
                hidden: market.tmdt,
              },
            customerDistrict: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) &&
              !market.wholesale && {
                text: "District",
                hidden: market.tmdt,
              },
            customerWard: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) &&
              !market.wholesale && {
                text: "Ward",
                hidden: market.tmdt,
              },
            customerAddress: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) && {
              text: "Address",
              hidden: market.tmdt,
            },
            customerPostalCode: userManager.checkRole(["admin", "kt", "vd", "vdl", "telesale"]) &&
              !market.wholesale && {
                text: "Postal Code",
                hidden: market.tmdt,
              },
            orderRequestLink: !market.tmdt &&
              !market.wholesale && {
                text: "Link",
                options: {
                  preTransform(_, item) {
                    return item.orderRequest?.link || item.orderRequest?.url || "";
                  },
                  href(_, item) {
                    return item.orderRequest?.link || item.orderRequest?.url || "";
                  },
                  attrs: {
                    target: "_blank",
                    // class: "text-truncate",
                    // style: "max-width: 200px; display: inline-block",
                  },
                },
              },
            orderRequestUtmSource: !market.tmdt &&
              !market.wholesale && {
                text: "utm_source",
                options: {
                  subProp: "orderRequest.utm_source",
                },
              },
            orderRequestUtmMedium: !market.tmdt &&
              !market.wholesale && {
                text: "utm_medium",
                options: {
                  subProp: "orderRequest.utm_medium",
                },
              },
            orderRequestUtmCampaign: !market.tmdt &&
              !market.wholesale && {
                text: "utm_campaign",
                options: {
                  subProp: "orderRequest.utm_campaign",
                },
              },
            telesaleStatus: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                text: "TT Telesale",
                sortable: true,
                hidden: true,
                options: {
                  label(value) {
                    return orderTelesaleStatuses[value || ""];
                  },
                },
              },
            telesaleNote: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "Telesale ghi chú",
                hidden: true,
              },
            locationStatus: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "TT Địa chỉ",
                sortable: true,
                hidden: true,
                options: {
                  label(value) {
                    return orderLocationStatuses[value || ""];
                  },
                },
              },
            locationNote: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "Ghi chú địa chỉ",
                hidden: true,
              },
            pickUpTime: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "Ngày Pickup",
                sortable: true,
                options: {
                  filter: "dateTime",
                },
              },
            pickUpStatus: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "TT Pickup",
                sortable: true,
                options: {
                  label(value) {
                    return orderPickUpStatuses[value || ""];
                  },
                },
              },
            dataSource: market.code.includes("-telesale") &&
              userManager.checkRole(["kt"]) && {
                text: "Nguồn data",
                sortable: true,
                options: {
                  label(value) {
                    return orderDataSources[value || ""];
                  },
                },
              },
            shippingStatus: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "TT Giao hàng",
              sortable: true,
              options: {
                label(value) {
                  return orderShippingStatuses[value || ""];
                },
              },
            },
            invalidStatus: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "TT Trùng đơn",
                hidden: market.tmdt,
              },
            additionalOrderNeed: !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Khách đặt thêm đơn",
                hidden: true,
                options: {
                  boolean: true,
                },
              },
            notAnswerCallCount: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "Số lần không nghe máy",
                hidden: true,
              },
            mktLink: !market.tmdt &&
              !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "MKT Link",
                options: {
                  subProp: "mktLink.name",
                  sortBy: "mktLinkId",
                  label: true,
                  attrs(_, item) {
                    const value: any = {};
                    if (item.mktLinkInvalid) {
                      value.color = "red";
                    } else if (item.mktDomainInvalid) {
                      value.color = "red";
                    }
                    return value;
                  },
                },
              },
            orderRequestDomain: !market.tmdt &&
              !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Tên miền",
                options: {
                  preTransform(_, item) {
                    const url = (item.orderRequest && item.orderRequest.link) || item.orderRequest?.url || "";
                    try {
                      return new URL(url).hostname;
                    } catch {
                      return "";
                    }
                  },
                  attrs(_, item) {
                    const value: any = {};
                    if (item.mktDomainInvalid) {
                      value.class = "red--text";
                    }
                    return value;
                  },
                },
              },
            chatPageId: !market.tmdt &&
              !userManager.checkRole(["content", "design"], true) &&
              !market.wholesale && {
                text: "Chat Page ID",
                options: {
                  label(value) {
                    return value || "[EMPTY]";
                  },
                  attrs(_, item) {
                    const value: any = {};
                    if (item.chatPageInvalid) {
                      value.color = "red";
                    }
                    return value;
                  },
                },
              },
            mktPlatform: !userManager.checkRole(["content", "design"], true) && {
              text: "Nền tảng MKT",
              hidden: market.tmdt,
              options: {
                subProp: "mktPlatform.name",
                sortBy: "mktPlatformId",
                label: true,
              },
            },
            mktUser: {
              text: "User MKT",
              options: {
                subProp: "mktUser.name",
                sortBy: "mktUserId",
                attrs(_, item) {
                  const attrs: any = {};
                  if (item.mktUser && item.mktUser.ht === "nv") {
                    attrs.class = "red--text";
                  }
                  return attrs;
                },
              },
            },
            contentUser: !market.tmdt &&
              !market.wholesale && {
                text: "User Content",
                options: {
                  subProp: "contentUser.name",
                  sortBy: "contentUserId",
                  attrs(_, item) {
                    const attrs: any = {};
                    if (item.contentUser && item.contentUser.ht === "nv") {
                      attrs.class = "red--text";
                    }
                    return attrs;
                  },
                },
              },
            designUser: !market.tmdt &&
              !market.wholesale && {
                text: "User Design",
                options: {
                  subProp: "designUser.name",
                  sortBy: "designUserId",
                  attrs(_, item) {
                    const attrs: any = {};
                    if (item.designUser && item.designUser.ht === "nv") {
                      attrs.class = "red--text";
                    }
                    return attrs;
                  },
                },
              },
            telesaleUser: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "User Telesale",
                hidden: true,
                options: {
                  subProp: "telesaleUser.name",
                  sortBy: "telesaleUserId",
                  attrs(_, item) {
                    const attrs: any = {};
                    if (item.telesaleUser && item.telesaleUser.ht === "nv") {
                      attrs.class = "red--text";
                    }
                    return attrs;
                  },
                },
              },
            locationUser: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "User Check địa chỉ",
                hidden: true,
                options: {
                  subProp: "telesaleUser.name",
                  sortBy: "telesaleUserId",
                  attrs(_, item) {
                    const attrs: any = {};
                    if (item.locationUser && item.locationUser.ht === "nv") {
                      attrs.class = "red--text";
                    }
                    return attrs;
                  },
                },
              },
            processUser: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "User Process",
                hidden: true,
                options: {
                  subProp: "processUser.name",
                  sortBy: "processUserId",
                  attrs(_, item) {
                    const attrs: any = {};
                    if (item.processUser && item.processUser.ht === "nv") {
                      attrs.class = "red--text";
                    }
                    return attrs;
                  },
                },
              },
            mktTeam: {
              text: "Team",
              options: {
                subProp: "mktTeam.name",
                sortBy: "mktTeamId",
              },
            },
            mktDepartment: {
              text: "Phòng",
              options: {
                subProp: "mktDepartment.name",
                sortBy: "mktDepartmentId",
              },
            },
            shippingCompany: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "ĐVVC",
                options: {
                  subProp: "shippingCompany.name",
                  sortBy: "shippingCompanyId",
                },
              },
            shippingCompanyAccount: userManager.checkRole(["kt", "vdl", "vd"]) &&
              !market.wholesale && {
                text: "Account ĐVVC",
                options: {
                  subProp: "shippingCompanyAccount.name",
                  sortBy: "shippingCompanyAccount",
                },
              },
            warehouse: !userManager.checkRole(["content", "design"], true) && {
              text: "Kho",
              options: {
                subProp: "warehouse.name",
                sortBy: "warehouseId",
              },
            },
            smsItems: !market.tmdt &&
              !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
              !market.wholesale && {
                text: "SMS",
                sortable: true,
                hidden: true,
                options: {
                  transform(v) {
                    return (v && v.length) || 0;
                  },
                },
              },
            transportTrackingNumberSmsSent: !market.tmdt && {
              text: "Đã gửi SMS Tracking",
              options: {
                boolean: true,
              },
            },
            transportTrackingNumber: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Tracking Number",
              sortable: true,
            },
            transportTrackingNumbers: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Tracking Numbers",
              options: {
                labels: true,
              },
            },
            realCodCost: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Phí THCOD",
              sortable: true,
              options: {
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            realShippingCost: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Phí ship",
              sortable: true,
              options: {
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            realCod: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Nhận COD",
              sortable: true,
              options: {
                filter: "number",
                preTransform(value, item) {
                  if (item.status === "return") {
                    return 0;
                  }
                  return value;
                },
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            realReceiveAmmount: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
              text: "Số tiền thực nhận",
              options: {
                preTransform(_, item) {
                  let value = (item.realCod || 0) - (item.realShippingCost || 0) - (item.realCodCost || 0);
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            shopeeId: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shopee ID",
              },
            returnStatus: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Return Status",
              },
            shipmentMethod: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shipment Method",
                sortable: true,
                options: {
                  label(value) {
                    return orderShipmentMethods[value || ""];
                  },
                },
              },
            estimatedShipOutDate: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Estimated Ship Out Date",
                sortable: true,
                options: {
                  filter: "dateTime",
                },
              },
            shipTime: market.tmdt && {
              text: "Ship Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            orderPaidTime: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Order Paid Time",
                sortable: true,
                options: {
                  filter: "dateTime",
                },
              },
            incomePaidTime: market.tmdt && {
              text: "Income Paid Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            shopeeRebate: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shopee Rebate",
              },
            numberOfItemsInOrder: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Number Of Items In Order",
              },
            voucherFee: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Voucher Fee",
              },
            sellerAbsorbedCoinCashback: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Seller Absorbed Coin Cashback",
              },
            shopeeVoucher: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shopee Voucher",
              },
            bundleDealsIndicator: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Bundle Deals Indicator",
                sortable: true,
                options: {
                  boolean: true,
                },
              },
            shopeeBundleDiscount: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shopee Bundle Discount",
              },
            sellerBundleDiscount: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Seller Bundle Discount",
              },
            shopeeCoinsOffset: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shopee Coins Offset",
              },
            creditCardDiscountTotal: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Credit Card Discount Total",
              },
            productPricePaidByBuyer: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Product Price Paid By Buyer",
              },
            buyerPaidShippingFee: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Buyer Paid Shipping Fee",
              },
            shippingRebateEstimate: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Shipping Rebatte Estimate",
              },
            reverseShippingFee: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Reverse Shipping Fee",
              },
            serviceFee: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Service Fee",
              },
            grandTotal: market.tmdt &&
              market.code.includes("-sh") && {
                text: "Grand Total",
              },
            estimatedShippingFee: market.tmdt && {
              text: "Estimated Shipping Fee",
            },
            buyerUsername: market.tmdt && {
              text: "Buyer Username",
            },
            customerTown: market.tmdt && {
              text: "Town",
            },
            customerRegion: market.tmdt && {
              text: "Region",
            },
            note: {
              text: "Note",
            },
            estimatedTransactionFee: market.tmdt && {
              text: "Estimated Transaction Fee",
            },
            commissionFee: market.tmdt && {
              text: "Commission Fee",
            },
            estimatedIncome: market.tmdt && {
              text: "Estimated Income",
              options: {
                filter: "number3",
              },
            },
            income: market.tmdt && {
              text: "Income",
              options: {
                filter: "number3",
              },
            },
            baseCost: userManager.checkRole(["kt"]) && {
              text: "Base Cost",
            },
            baseCostOk: market.tmdt && {
              text: "Base Cost OK",
              options: {
                boolean: true,
              },
            },
            shop: market.tmdt && {
              text: "Shop",
              options: {
                subProp: "shop.name",
                sortBy: "xShopId",
              },
            },
            lazId: market.tmdt &&
              market.code.includes("-lz") && {
                text: "Lazada ID",
              },
            lazSku: market.tmdt &&
              market.code.includes("-lz") && {
                text: "Lazada SKU",
              },
            invoiceRequired: market.tmdt &&
              market.code.includes("-lz") && {
                text: "Invoice Required",
                options: {
                  boolean: true,
                },
              },
            payMethod: market.tmdt &&
              market.code.includes("-lz") && {
                text: "Pay Method",
              },
            tiktokId: market.tmdt && market.code.includes("-ttshop") && { text: "Tiktok ID" },
            tiktokAdjustmentId: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Adjustment ID",
              },
            subStatus: market.tmdt && market.code.includes("-ttshop") && { text: "Substatus" },
            cancelationOrReturnType: market.tmdt && market.code.includes("-ttshop") && { text: "Cancel or Return" },
            preOrder: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Preorder",
                options: { boolean: true },
              },
            tiktokSkuId: market.tmdt && market.code.includes("-ttshop") && { text: "Tiktok SKU ID" },
            returnedCount: market.tmdt && market.code.includes("-ttshop") && { text: "SL Return" },
            tiktokPackageId: market.tmdt && market.code.includes("-ttshop") && { text: "Tiktok Package ID" },
            tiktokProductCategory: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Product Category",
              },
            tiktokDeliveryOption: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Delivery Option",
              },
            tiktokCancelReason: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Cancel Reason",
              },
            tiktokOrderAmount: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Order Amount",
              },
            tiktokSubtotal: market.tmdt && market.code.includes("-ttshop") && { text: "Tiktok Subtotal" },
            tiktokReturned: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Returned",
                options: { boolean: true },
              },
            creatorUsername: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Creator",
                sortable: true,
              },
            tiktokAdjustmentValue: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Adjustment Value",
                options: {
                  filter: "number3",
                },
              },
            tiktokCommissionRate: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Commission Rate",
                options: {
                  filter: "number3",
                },
              },
            tiktokCommissionFee: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Commission Fee",
                options: {
                  filter: "number3",
                },
              },
            tiktokRealCommissionFee: market.tmdt &&
              market.code.includes("-ttshop") && {
                text: "Tiktok Commission Fee thực",
                options: {
                  filter: "number3",
                },
              },
            action: {},
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in_id: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mainProductGroupIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm chính",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktPlatformId: !userManager.checkRole(["content", "design"], true) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              shippingCompanyId: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              shippingCompanyAccountId: userManager.checkRole(["kt", "vdl", "vd"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Account ĐVVC",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanyAccounts", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                    ext: {
                      defaultValue: moment().subtract(3, "months").startOf("day").valueOf(),
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              dxkTime: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày xuất kho",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              returnTime: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày return",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày return (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày return (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              status: {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(orderStatuses),
                },
                rules: {
                  in: {},
                },
              },
              telesaleStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Trạng thái telesale",
                    items: makeSelectOptions(orderTelesaleStatuses),
                  },
                  rules: {
                    in: {},
                  },
                },
              lastTelesaleStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Last Calling Status",
                    items: makeSelectOptions(orderTelesaleStatusesEn),
                  },
                  rules: {
                    in: {},
                  },
                },
              lastTelesaleStatusUpdatedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Call Time",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (Start)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Call Time (End)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              locationStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Trạng thái địa chỉ",
                    items: makeSelectOptions(orderLocationStatuses),
                  },
                  rules: {
                    in: {},
                  },
                },
              pickUpStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Trạng thái pick up",
                    items: makeSelectOptions(orderPickUpStatuses),
                  },
                  rules: {
                    in: {},
                  },
                },
              telesaleUserId: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "XAutocomplete",
                  attrs: {
                    label: "User Telesale",
                    "item-value": "_id",
                    "item-text": "name",
                    "item-disabled": "_",
                    xOptions: {
                      content: {
                        async itemsSuggestor(search, value, limit) {
                          const { items } = await coreApiClient.call("users", "findAll", {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          });
                          return items;
                        },
                      },
                    },
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              processUserId: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "XAutocomplete",
                  attrs: {
                    label: "User Process",
                    "item-value": "_id",
                    "item-text": "name",
                    "item-disabled": "_",
                    xOptions: {
                      content: {
                        async itemsSuggestor(search, value, limit) {
                          const { items } = await coreApiClient.call("users", "findAll", {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          });
                          return items;
                        },
                      },
                    },
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              locationUserId: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "XAutocomplete",
                  attrs: {
                    label: "User Check địa chỉ",
                    "item-value": "_id",
                    "item-text": "name",
                    "item-disabled": "_",
                    xOptions: {
                      content: {
                        async itemsSuggestor(search, value, limit) {
                          const { items } = await coreApiClient.call("users", "findAll", {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          });
                          return items;
                        },
                      },
                    },
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              contentUserId: !market.tmdt && {
                type: "XAutocomplete",
                attrs: {
                  label: "User Content",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              designUserId: !market.tmdt && {
                type: "XAutocomplete",
                attrs: {
                  label: "User Design",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              customerName: !userManager.checkRole(["content", "design"], true) && {
                attrs: {
                  label: "Tên KH",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerIp: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                attrs: {
                  label: "IP",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerEmail: !userManager.checkRole(["content", "design"], true) && {
                attrs: {
                  label: "Email",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerPhone: !userManager.checkRole(["content", "design"], true) && {
                attrs: {
                  label: "SĐT",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerMessage: !userManager.checkRole(["content", "design"], true) && {
                attrs: {
                  label: "Lời nhắn",
                },
                rules: {
                  equal_to: {},
                },
              },
              transportTrackingNumber: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                attrs: {
                  label: "Tracking Number",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productRowItems.transportTrackingNumber": !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
                market.tmdt && {
                  attrs: {
                    label: "Tracking Number (sản phẩm)",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              smsItemsLength: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "select",
                  attrs: {
                    label: "Trạng thái gửi SMS",
                    items: [
                      { value: 0, text: "Chưa gửi" },
                      { value: 1, text: "Lần 1" },
                      { value: 2, text: "Lần 2" },
                      { value: 3, text: "Lần 3" },
                      { value: 4, text: "Lần 4" },
                    ],
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              customerRefusedStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Trạng thái hủy đơn",
                    items: makeSelectOptions(orderCustomerRefusedStatuses),
                  },
                  rules: {
                    in: {},
                  },
                },
              dtStatus: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Lý do hủy đơn trùng",
                    items: makeSelectOptions(orderDtStatuses),
                  },
                  rules: {
                    in: {},
                  },
                },
              customerIpCountryInvalid: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "IP khác quốc gia",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              customerIpCoincide: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "IP trùng so với tất cả các đơn hàng",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              customerIpCoincideRecent: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "IP trùng gần đây",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              additionalOrderNeed: !market.tmdt &&
                !userManager.checkRole(["content", "design"], true) && {
                  type: "boolean",
                  attrs: {
                    label: "Khách đặt thêm đơn",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              notAnswerCallCount: !market.tmdt &&
                !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                  type: "integer",
                  attrs: {
                    label: "Số lần không nghe máy",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              shippingStatus: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái giao hàng",
                  items: [
                    {
                      text: "Trống",
                      value: "",
                    },
                  ].concat(makeSelectOptions(orderShippingStatuses)),
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      let hasEmptyFilter = false;
                      const arr: any = [];
                      for (const item of value) {
                        if (!item) {
                          hasEmptyFilter = true;
                        } else {
                          arr.push(item);
                        }
                      }
                      const subFilters = [];
                      if (hasEmptyFilter) {
                        subFilters.push({
                          key: "shippingStatus",
                          operator: "not_has_property",
                        });
                      } else if (arr.length) {
                        return [
                          {
                            key: "shippingStatus",
                            operator: "in",
                            value: arr,
                          },
                        ];
                      }
                      if (arr.length) {
                        subFilters.push({
                          key: "shippingStatus",
                          operator: "in",
                          value: arr,
                        });
                      }
                      return [
                        {
                          operator: "or",
                          subFilters,
                        },
                      ];
                    },
                  },
                },
              },
              "productRowItems.shippingStatus": !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) &&
                market.tmdt && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Trạng thái giao hàng (sản phẩm)",
                    items: [
                      {
                        text: "Trống",
                        value: "",
                      },
                    ].concat(makeSelectOptions(orderShippingStatuses)),
                  },
                  rules: {
                    custom: {
                      filterMaker(value) {
                        let hasEmptyFilter = false;
                        const arr: any = [];
                        for (const item of value) {
                          if (!item) {
                            hasEmptyFilter = true;
                          } else {
                            arr.push(item);
                          }
                        }
                        const subFilters = [];
                        if (hasEmptyFilter) {
                          subFilters.push({
                            key: "productRowItems.shippingStatus",
                            operator: "not_has_property",
                          });
                        } else if (arr.length) {
                          return [
                            {
                              key: "productRowItems.shippingStatus",
                              operator: "in",
                              value: arr,
                            },
                          ];
                        }
                        if (arr.length) {
                          subFilters.push({
                            key: "productRowItems.shippingStatus",
                            operator: "in",
                            value: arr,
                          });
                        }
                        return [
                          {
                            operator: "or",
                            subFilters,
                          },
                        ];
                      },
                    },
                  },
                },
              customerMessageExist: !userManager.checkRole(["content", "design"], true) && {
                type: "select",
                attrs: {
                  label: "Có lời nhắn",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              warehouseId: userManager.checkRole(["kt", "qlk", "vd", "vdl"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktLinkId: !market.tmdt && {
                type: "XAutocomplete",
                attrs: {
                  label: "MKT Link",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("mktLinks", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              mktDomainInvalid: !market.tmdt && {
                type: "boolean",
                attrs: {
                  label: "MKT Domain không hợp lệ",
                },
                rules: {
                  equal_to: {},
                },
              },
              mktLinkInvalid: !market.tmdt && {
                type: "boolean",
                attrs: {
                  label: "MKT Link không hợp lệ",
                },
                rules: {
                  equal_to: {},
                },
              },
              chatPage: !market.tmdt && {
                type: "boolean",
                attrs: {
                  label: "Đơn Chat Page",
                },
                rules: {
                  equal_to: {},
                },
              },
              chatPageInvalid: !market.tmdt && {
                type: "boolean",
                attrs: {
                  label: "Chat Page ID không hợp lệ",
                },
                rules: {
                  equal_to: {},
                },
              },
              chatPageId: !market.tmdt && {
                type: "text",
                attrs: {
                  label: "Chat Page ID",
                },
                rules: {
                  equal_to: {},
                },
              },
              xShopId: market.tmdt && {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("shops", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              shopeeId: market.tmdt &&
                market.code.includes("-sh") && {
                  type: "text",
                  attrs: {
                    label: "Shopee ID",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              lazId: market.tmdt &&
                market.code.includes("-lz") && {
                  type: "text",
                  attrs: {
                    label: "Lazada ID",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              tiktokId: market.tmdt &&
                market.code.includes("-ttshop") && {
                  type: "text",
                  attrs: {
                    label: "Tiktok ID",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              returned: userManager.checkRole(["kt", "vd", "vdl"]) && {
                type: "select",
                attrs: {
                  label: "Đơn thực tế về kho",
                  items: [
                    { value: "", text: "Cả hai" },
                    { value: "yes", text: "Đã về" },
                    { value: "no", text: "Chưa về" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "returned",
                            operator: "equal_to",
                            value: true,
                          },
                        ];
                      } else if (value === "no") {
                        return [
                          {
                            key: "returned",
                            operator: "not_equal_to",
                            value: true,
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              "productRowItems.returned": userManager.checkRole(["kt", "vd", "vdl"]) && {
                type: "select",
                attrs: {
                  label: "Đơn thực tế về kho (sản phẩm)",
                  items: [
                    { value: "", text: "Cả hai" },
                    { value: "yes", text: "Đã về" },
                    { value: "no", text: "Chưa về" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "productRowItems.returned",
                            operator: "equal_to",
                            value: true,
                          },
                        ];
                      } else if (value === "no") {
                        return [
                          {
                            key: "productRowItems.returned",
                            operator: "in",
                            value: [false, null],
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              incomeItemAlerted: userManager.checkRole(["kt", "vd", "vdl"]) &&
                market.tmdt && {
                  type: "select",
                  attrs: {
                    label: "Cảnh báo thành phần income",
                    items: [
                      { value: "", text: "Cả hai" },
                      { value: "yes", text: "Có" },
                      { value: "no", text: "Không" },
                    ],
                  },
                  rules: {
                    custom: {
                      filterMaker(value) {
                        if (value === "yes") {
                          return [
                            {
                              key: "incomeItemAlerted",
                              operator: "equal_to",
                              value: true,
                            },
                          ];
                        } else if (value === "no") {
                          return [
                            {
                              key: "incomeItemAlerted",
                              operator: "not_equal_to",
                              value: true,
                            },
                          ];
                        }
                        return [];
                      },
                    },
                  },
                },
              productInvalid: {
                type: "boolean",
                attrs: {
                  label: "Thiếu sản phẩm",
                },
                rules: {
                  equal_to: {},
                },
              },
              customerIpCountry: {
                attrs: {
                  label: "IP Country",
                },
                rules: {
                  equal_to: {},
                },
              },
              chatPageIdExists: !market.tmdt &&
                !userManager.checkRole(["content", "design"], true) && {
                  type: "select",
                  attrs: {
                    label: "Có Chat Page ID",
                    items: [
                      { value: "", text: "Trống" },
                      { value: "yes", text: "Có" },
                      { value: "no", text: "Không" },
                    ],
                  },
                  rules: {
                    custom: {
                      filterMaker(value) {
                        if (value === "yes") {
                          return [
                            {
                              key: "chatPageId",
                              operator: "not_equal_to",
                              value: "",
                            },
                            {
                              key: "chatPageId",
                              operator: "has_property",
                            },
                          ];
                        } else if (value === "no") {
                          return [
                            {
                              operator: "or",
                              subFilters: [
                                {
                                  key: "chatPageId",
                                  operator: "equal_to",
                                  value: "",
                                },
                                {
                                  key: "chatPageId",
                                  operator: "not_has_property",
                                },
                              ],
                            },
                            {
                              key: "customerName",
                              operator: "not_in",
                              value: ["test", "Test", "TEST"],
                            },
                          ];
                        }
                        return [];
                      },
                    },
                  },
                },
              remmitanceDataNotExist: !userManager.checkRole(["mkt", "tp", "rd", "rdl", "content", "design"], true) && {
                type: "boolean",
                attrs: {
                  label: "Không có dữ liệu Remittance",
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value) {
                        return [
                          {
                            key: "realCod",
                            operator: "equal_to",
                            value: 0,
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              tiktokIsSampleRequest: market.tmdt &&
                market.code.includes("-ttshop") && {
                  type: "boolean",
                  attrs: {
                    label: "Tiktok sample request",
                  },
                  rules: {
                    custom: {
                      filterMaker(value) {
                        if (value) {
                          return {
                            key: "tiktokIsSampleRequest",
                            operator: "equal_to",
                            value: true,
                          };
                        } else {
                          return {
                            key: "tiktokIsSampleRequest",
                            operator: "not_equal_to",
                            value: true,
                          };
                        }
                      },
                    },
                  },
                },
              tiktokCreatorUsername: market.tmdt &&
                market.code.includes("-ttshop") && {
                  attrs: {
                    label: "Tiktok Creator",
                  },
                  rules: {
                    equal_to: {},
                  },
                },
              "orderRequest.utm_source": !market.tmdt && {
                attrs: {
                  label: "utm_source",
                },
                rules: {
                  equal_to: {},
                },
              },
              "orderRequest.utm_medium": !market.tmdt && {
                attrs: {
                  label: "utm_medium",
                },
                rules: {
                  equal_to: {},
                },
              },
              "orderRequest.utm_campaign": !market.tmdt && {
                attrs: {
                  label: "utm_campaign",
                },
                rules: {
                  equal_to: {},
                },
              },
              overriddenSinglePriceExisted:
                market.code.includes("telesale") || market.code.includes("test-fb")
                  ? {
                      type: "select",
                      attrs: {
                        label: "Thay đổi giá",
                        items: [
                          { value: "", text: "Trống" },
                          { value: "yes", text: "Có" },
                          { value: "no", text: "Không" },
                        ],
                      },
                      rules: {
                        custom: {
                          filterMaker(value) {
                            if (value === "yes") {
                              return [
                                {
                                  key: "overriddenSinglePrice",
                                  operator: "greater_than",
                                  value: 0,
                                },
                              ];
                            } else if (value === "no") {
                              return {
                                operator: "or",
                                subFilters: [
                                  {
                                    key: "overriddenSinglePrice",
                                    operator: "equal_to",
                                    value: 0,
                                  },
                                  {
                                    key: "overriddenSinglePrice",
                                    operator: "not_has_property",
                                  },
                                ],
                              };
                            }
                            return [];
                          },
                        },
                      },
                    }
                  : undefined,
              mainProductGroupIdsFailed: userManager.checkRole(["kt"]) && {
                type: "select",
                attrs: {
                  label: "Lỗi nhóm sản phẩm",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Lỗi" },
                    { value: "no", text: "Không lỗi" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "mainProductGroupIds.0",
                              operator: "not_has_property",
                            },
                            {
                              key: "mainProductGroupIds.0",
                              operator: "equal_to",
                              value: null,
                            },
                          ],
                        };
                      } else if (value === "no") {
                        return [
                          {
                            key: "mainProductGroupIds.0",
                            operator: "has_property",
                          },
                          {
                            key: "mainProductGroupIds.0",
                            operator: "not_equal_to",
                            value: null,
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              reOrderCount: userManager.checkRole(["kt"]) &&
                !market.tmdt &&
                !market.wholesale && {
                  type: "number",
                  attrs: {
                    label: "Số lần re-order",
                  },
                  rules: {
                    greater_than_or_equal_to: {
                      attrs: {
                        label: "Số lần re-order (>=)",
                      },
                    },
                    less_than_or_equal_to: {
                      attrs: {
                        label: "Số lần re-order (<=)",
                      },
                    },
                  },
                },
              dataSource: market.code.includes("-telesale") &&
                userManager.checkRole(["kt"]) && {
                  type: "selectMultiple",
                  attrs: {
                    label: "Nguồn data",
                    items: makeSelectOptions(orderDataSources),
                  },
                  rules: {
                    in: {},
                  },
                },
            },
          },
          topActionButtons: {
            export: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              target: {
                menu: {
                  content: {
                    items: {
                      export: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                        content: {
                          text: "Xuất Excel",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                      export2: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                        content: {
                          text: "Xuất Excel (+DSSP)",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(findAllOptions.payload);
                              payload.extraParams = {
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                      export3: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                        content: {
                          text: "Xuất Excel (Base cost)",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(findAllOptions.payload);
                              payload.extraParams = {
                                fieldSet: "baseCost",
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                      export4: market.code.includes("telesale") &&
                        userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                          content: {
                            text: "Xuất Excel (Telesale cứu đơn)",
                          },
                          on: {
                            xClick({ self }) {
                              const dataTable = self.context().context();
                              let findAllOptions = dataTable.getFindAllOptions();
                              if (dataTable.model.value.length) {
                                findAllOptions = {
                                  payload: JSON.stringify({
                                    filters: [
                                      {
                                        key: "_id",
                                        operator: "in",
                                        value: dataTable.model.value.map((item) => item._id),
                                      },
                                    ],
                                    limit: -1,
                                  }),
                                };
                              }
                              {
                                const payload = JSON.parse(findAllOptions.payload);
                                payload.extraParams = {
                                  fieldSet: "import2",
                                  withProductItems: true,
                                };
                                findAllOptions.payload = JSON.stringify(payload);
                              }
                              coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                            },
                          },
                        },
                      export5: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                        content: {
                          text: "Xuất Excel (Check ĐVVC)",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(findAllOptions.payload);
                              payload.extraParams = {
                                fieldSet: "import3",
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                      exportWholesale: userManager.checkRole(["vd", "vdl", "kt", "ksnb", "ksnbl"]) && {
                        content: {
                          text: "Xuất Excel (Khách sỉ)",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(findAllOptions.payload);
                              payload.extraParams = {
                                fieldSet: "wholesale",
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                      exportPaidPrice: userManager.checkRole(["kt", "ksnb", "ksnbl"]) &&
                        market.tmdt && {
                          content: {
                            text: "Xuất Excel (Giá bán)",
                          },
                          on: {
                            xClick({ self }) {
                              const dataTable = self.context().context();
                              let findAllOptions = dataTable.getFindAllOptions();
                              if (dataTable.model.value.length) {
                                findAllOptions = {
                                  payload: JSON.stringify({
                                    filters: [
                                      {
                                        key: "_id",
                                        operator: "in",
                                        value: dataTable.model.value.map((item) => item._id),
                                      },
                                      {
                                        key: "marketId",
                                        operator: "equal_to",
                                        value: market._id,
                                      },
                                    ],
                                    limit: -1,
                                  }),
                                };
                              }
                              {
                                const payload = JSON.parse(findAllOptions.payload);
                                payload.extraParams = {
                                  fieldSet: "paidPrice",
                                  withProductItems: true,
                                };
                                findAllOptions.payload = JSON.stringify(payload);
                              }
                              coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                            },
                          },
                        },
                      exportIncomeItems: userManager.checkRole(["kt"]) &&
                        market.tmdt && {
                          content: {
                            text: "Xuất Excel (Thành phần income)",
                          },
                          on: {
                            xClick({ self }) {
                              const dataTable = self.context().context();
                              let findAllOptions = dataTable.getFindAllOptions();
                              if (dataTable.model.value.length) {
                                findAllOptions = {
                                  payload: JSON.stringify({
                                    filters: [
                                      {
                                        key: "_id",
                                        operator: "in",
                                        value: dataTable.model.value.map((item) => item._id),
                                      },
                                      {
                                        key: "marketId",
                                        operator: "equal_to",
                                        value: market._id,
                                      },
                                    ],
                                    limit: -1,
                                  }),
                                };
                              }
                              {
                                const payload = JSON.parse(findAllOptions.payload);
                                payload.extraParams = {
                                  fieldSet: "incomeItems",
                                };
                                findAllOptions.payload = JSON.stringify(payload);
                              }
                              coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                            },
                          },
                        },
                      exportProductRowItems: userManager.checkRole(["kt", "vdl", "vd"]) && {
                        content: {
                          text: "Xuất Excel (Danh sách SP TMDT)",
                        },
                        on: {
                          xClick({ self }) {
                            const dataTable = self.context().context();
                            let findAllOptions = dataTable.getFindAllOptions();
                            if (dataTable.model.value.length) {
                              findAllOptions = {
                                payload: JSON.stringify({
                                  filters: [
                                    {
                                      key: "_id",
                                      operator: "in",
                                      value: dataTable.model.value.map((item) => item._id),
                                    },
                                    {
                                      key: "marketId",
                                      operator: "equal_to",
                                      value: market._id,
                                    },
                                  ],
                                  limit: -1,
                                }),
                              };
                            }
                            {
                              const payload = JSON.parse(findAllOptions.payload);
                              payload.extraParams = {
                                fieldSet: "productRowItems",
                                withProductItems: true,
                              };
                              findAllOptions.payload = JSON.stringify(payload);
                            }
                            coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            sms: !market.tmdt &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  text: "SMS",
                  icon: "mdi-message-reply-text",
                },
                target: {
                  menu: {
                    content: {
                      items: {
                        exportSms: {
                          content: {
                            text: "Xuất SMS",
                          },
                          on: {
                            xClick({ self }) {
                              const dataTable = self.context().context();
                              const ctx = dataTable.context();
                              let findAllOptions = dataTable.getFindAllOptions();
                              if (dataTable.model.value.length) {
                                findAllOptions = {
                                  payload: JSON.stringify({
                                    filters: [
                                      {
                                        key: "_id",
                                        operator: "in",
                                        value: dataTable.model.value.map((item) => item._id),
                                      },
                                    ],
                                    limit: -1,
                                  }),
                                };
                              }
                              coreApiWorkerClient.download("orders", `@/exportSms/${ctx.$route.params.marketId}`, findAllOptions);
                            },
                          },
                        },
                        importSms: {
                          content: {
                            text: "Cập nhật SMS",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Cập nhật SMS",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              file: {
                                                type: "file",
                                                attrs: {
                                                  label: "File",
                                                  required: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Tải lên",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call("orders", "importSms", {}, data);
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          dialog.hide();
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        sendSms: {
                          attrs: {
                            color: "default",
                            dark: false,
                          },
                          content: {
                            text: "Gửi SMS",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Gửi SMS",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              templateIndex: {
                                                type: "select",
                                                attrs: {
                                                  label: "Mẫu",
                                                  required: true,
                                                  items: setting.smsTemplateItems.map((item, index) => {
                                                    return {
                                                      value: `${index}`,
                                                      text: item.name,
                                                    };
                                                  }),
                                                },
                                              },
                                              senderName: {
                                                type: "select",
                                                attrs: {
                                                  label: "Sender name",
                                                  required: true,
                                                  items: setting.smsSenderNames.map((item) => {
                                                    return {
                                                      value: item,
                                                      text: item,
                                                    };
                                                  }),
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Gửi",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        let findAllOptions = dataTable.getFindAllOptions();
                                        if (dataTable.model.value.length) {
                                          findAllOptions = {
                                            payload: JSON.stringify({
                                              filters: [
                                                {
                                                  key: "_id",
                                                  operator: "in",
                                                  value: dataTable.model.value.map((item) => item._id),
                                                },
                                              ],
                                              limit: -1,
                                            }),
                                          };
                                        }
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call(
                                              "orders",
                                              "sendSms",
                                              {
                                                marketId: market._id,
                                                templateIndex: data.templateIndex,
                                                senderName: data.senderName,
                                              },
                                              findAllOptions
                                            );
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          dialog.hide();
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            import: !market.tmdt &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-cloud-upload",
                  text: "Import",
                },
                target: {
                  menu: {
                    content: {
                      items: {
                        importLadiPage: {
                          content: {
                            text: "LadiPage",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Cập nhật dữ liệu backup từ LadiPage",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              mktLinkId: {
                                                type: "XAutocomplete",
                                                attrs: {
                                                  label: "Mkt Link",
                                                  required: true,
                                                  "item-value": "_id",
                                                  "item-text": "name",
                                                  xOptions: {
                                                    content: {
                                                      async itemsSuggestor(search, value, limit) {
                                                        const { items } = await coreApiClient.call("mktLinks", "findAll", {
                                                          payload: JSON.stringify({
                                                            limit,
                                                            search,
                                                            filters: [
                                                              {
                                                                key: "marketId",
                                                                operator: "equal_to",
                                                                value: market._id,
                                                              },
                                                              {
                                                                key: "_id",
                                                                operator: "equal_to",
                                                                value,
                                                              },
                                                            ],
                                                          }),
                                                        });
                                                        return items;
                                                      },
                                                    },
                                                  },
                                                },
                                              },
                                              file: {
                                                type: "file",
                                                attrs: {
                                                  label: "File",
                                                  required: true,
                                                },
                                              },
                                              ignoreConcidence: {
                                                type: "boolean",
                                                attrs: {
                                                  label: "Bỏ qua check trùng đơn",
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Tải lên",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call(
                                              "integrationLadiPage",
                                              "import",
                                              {
                                                mktLinkId: data.mktLinkId,
                                                ignoreConcidence: `${!!data.ignoreConcidence}`,
                                              },
                                              data
                                            );
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          const lines = [
                                            `Import thành công ${result.createdOrderIds.length} đơn: ${result.createdOrderIds.join(",")}`,
                                            `Tương ứng với các hàng ${result.createdIndexes.join(",")}`,
                                            "",
                                            `${result.notChangedOrderIds.length} đơn đã tồn tại: ${result.notChangedOrderIds.join(",")}`,
                                            `Tương ứng với các hàng ${result.notChangedIndexes.join(",")}`,
                                            "",
                                            `Các hàng bị lỗi ${result.errorIndexes.join(",")}`,
                                          ];
                                          form.success.value = lines.join("<br/>");
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        importLadiPageChatPage: {
                          content: {
                            text: "LadiPage Chat Page",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Cập nhật dữ liệu từ LadiPage Chat Page",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              file: {
                                                type: "file",
                                                attrs: {
                                                  label: "File",
                                                  required: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Tải lên",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call(
                                              "integrationLadiPage",
                                              "importChatPage",
                                              {
                                                marketId: market._id,
                                              },
                                              data
                                            );
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          const lines = [
                                            `Import thành công ${result.createdOrderIds.length} đơn: ${result.createdOrderIds.join(",")}`,
                                            `Tương ứng với các hàng ${result.createdIndexes.join(",")}`,
                                            "",
                                            `${result.notChangedOrderIds.length} đơn đã tồn tại: ${result.notChangedOrderIds.join(",")}`,
                                            `Tương ứng với các hàng ${result.notChangedIndexes.join(",")}`,
                                            "",
                                            `Các hàng bị lỗi ${result.errorIndexes.join(",")}`,
                                          ];
                                          form.success.value = lines.join("<br/>");
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        importTelesale: market.code.includes("telesale") && {
                          content: {
                            text: "Telesale cứu đơn",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Import (Telesale cứu đơn)",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              file: {
                                                type: "file",
                                                attrs: {
                                                  label: "File",
                                                  required: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Tải lên",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call(
                                              "orders",
                                              "import2",
                                              {
                                                marketId: market._id,
                                              },
                                              data
                                            );
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          dialog.hide();
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        importWholesale: market.wholesale && {
                          content: {
                            text: "Khách sỉ",
                          },
                          target: {
                            dialog: {
                              handlers: {
                                initialize() {
                                  this.options.content.buttons.save.states.enabled = new DataContainer(false);
                                },
                              },
                              content: {
                                toolbar: {
                                  title: "Import (Khách sỉ)",
                                },
                                content: {
                                  type: "XForm",
                                  makeAttrs(attrs, dialog) {
                                    attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                                    attrs.xContext = dialog;
                                    attrs.xData = new DataContainer({});
                                    return attrs;
                                  },
                                  attrs: {
                                    xOptions: {
                                      content: {
                                        sections: {
                                          default: {
                                            fields: {
                                              file: {
                                                type: "file",
                                                attrs: {
                                                  label: "File",
                                                  required: true,
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                buttons: {
                                  save: {
                                    content: {
                                      text: "Tải lên",
                                    },
                                    states: {},
                                    on: {
                                      async xClick({ self }) {
                                        const dialog = self.context();
                                        const menu = dialog.context();
                                        const dataTable = menu.context().context();
                                        const form = dialog.contentContainer.value;
                                        const data = form.getData();
                                        self.loading.value = true;
                                        const result = await dataTable.execute(
                                          async () => {
                                            return await coreApiClient.call(
                                              "orders",
                                              "importWholesale",
                                              {
                                                marketId: market._id,
                                              },
                                              data
                                            );
                                          },
                                          undefined,
                                          {
                                            disableLoading: true,
                                            willRefresh: true,
                                          }
                                        );
                                        self.loading.value = false;
                                        if (result) {
                                          dialog.hide();
                                        }
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            importShopee: market.tmdt &&
              market.code.includes("-sh") &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-basket",
                  text: "Shopee",
                },
                target: {
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật dữ liệu từ Shopee",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    xShopId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Shop",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("shops", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "marketId",
                                                      operator: "equal_to",
                                                      value: market._id,
                                                    },
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    warehouseId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Kho",
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("warehouses", "findAll", {
                                                payload: JSON.stringify({
                                                  limit: -1,
                                                  filters: [
                                                    {
                                                      key: "countryId",
                                                      operator: "equal_to",
                                                      value: market.countryId,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    productItemsLocked: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Khóa sản phẩm",
                                      },
                                    },
                                    file: {
                                      type: "file",
                                      attrs: {
                                        label: "File",
                                      },
                                    },
                                    incomeFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Income File",
                                      },
                                    },
                                    adjustmentFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Adjustment File",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Tải lên",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const dataTable = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const { files: result } = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "integrationShopee",
                                    "import",
                                    {
                                      marketId: market._id,
                                      xShopId: data.xShopId,
                                      warehouseId: data.warehouseId || "@any",
                                      productItemsLocked: `${data.productItemsLocked || "@any"}`,
                                    },
                                    data
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                const fileResult = result.find((f) => f.id === "file");
                                const incomeFileResult = result.find((f) => f.id === "incomeFile");
                                const adjustmentFileResult = result.find((f) => f.id === "adjustmentFile");
                                let lines = [];
                                if (fileResult) {
                                  lines = lines.concat([
                                    "File:",
                                    `Tạo mới thành công ${fileResult.createdOrderIds.length} đơn: ${fileResult.createdOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.createdIndexes.join(",")}`,
                                    "",
                                    `Cập nhật thành công ${fileResult.updatedOrderIds.length} đơn: ${fileResult.updatedOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.updatedIndexes.join(",")}`,
                                    "",
                                    `Các hàng bị lỗi ${fileResult.errorIndexes.join(",")}`,
                                  ]);
                                }
                                if (incomeFileResult) {
                                  lines = lines.concat(["", "", "Income File: Cập nhật thành công"]);
                                }
                                if (adjustmentFileResult) {
                                  lines = lines.concat(["", "", "Adjustment File: Cập nhật thành công"]);
                                }
                                form.success.value = lines.join("<br/>");
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            importLaz: market.tmdt &&
              market.code.includes("-lz") &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-shopping-outline",
                  text: "Lazada",
                },
                target: {
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật dữ liệu từ Lazada",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    xShopId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Shop",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("shops", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "marketId",
                                                      operator: "equal_to",
                                                      value: market._id,
                                                    },
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    warehouseId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Kho",
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("warehouses", "findAll", {
                                                payload: JSON.stringify({
                                                  limit: -1,
                                                  filters: [
                                                    {
                                                      key: "countryId",
                                                      operator: "equal_to",
                                                      value: market.countryId,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    productItemsLocked: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Khóa sản phẩm",
                                      },
                                    },
                                    file: {
                                      type: "file",
                                      attrs: {
                                        label: "File",
                                      },
                                    },
                                    estimatedIncomeFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Estimated Income File",
                                      },
                                    },
                                    incomeFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Income File",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Tải lên",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const dataTable = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const { files: result } = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "integrationLazada",
                                    "import",
                                    {
                                      marketId: market._id,
                                      xShopId: data.xShopId,
                                      warehouseId: data.warehouseId || "@any",
                                      productItemsLocked: `${data.productItemsLocked || "@any"}`,
                                    },
                                    data
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                const fileResult = result.find((f) => f.id === "file");
                                const estimatedIncomeFileResult = result.find((f) => f.id === "estimatedIncomeFile");
                                const incomeFileResult = result.find((f) => f.id === "incomeFile");
                                let lines = [];
                                if (fileResult) {
                                  lines = lines.concat([
                                    "File:",
                                    `Tạo mới thành công ${fileResult.createdOrderIds.length} đơn: ${fileResult.createdOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.createdIndexes.join(",")}`,
                                    "",
                                    `Cập nhật thành công ${fileResult.updatedOrderIds.length} đơn: ${fileResult.updatedOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.updatedIndexes.join(",")}`,
                                    "",
                                    `Các hàng bị lỗi ${fileResult.errorIndexes.join(",")}`,
                                  ]);
                                }
                                if (estimatedIncomeFileResult) {
                                  lines = lines.concat(["", "", "Estimated Income File: Cập nhật thành công"]);
                                }
                                if (incomeFileResult) {
                                  lines = lines.concat(["", "", "Income File: Cập nhật thành công"]);
                                }
                                form.success.value = lines.join("<br/>");
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            importTiktokShop: market.tmdt &&
              market.code.includes("-ttshop") &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-basket",
                  text: "Tiktok Shop",
                },
                target: {
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật dữ liệu từ Tiktok Shop",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    xShopId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Shop",
                                        required: true,
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsSuggestor(search, value, limit) {
                                              const { items } = await coreApiClient.call("shops", "findAll", {
                                                payload: JSON.stringify({
                                                  limit,
                                                  search,
                                                  filters: [
                                                    {
                                                      key: "marketId",
                                                      operator: "equal_to",
                                                      value: market._id,
                                                    },
                                                    {
                                                      key: "_id",
                                                      operator: "equal_to",
                                                      value,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    warehouseId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Kho",
                                        "item-value": "_id",
                                        "item-text": "name",
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("warehouses", "findAll", {
                                                payload: JSON.stringify({
                                                  limit: -1,
                                                  filters: [
                                                    {
                                                      key: "countryId",
                                                      operator: "equal_to",
                                                      value: market.countryId,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    productItemsLocked: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Khóa sản phẩm",
                                      },
                                    },
                                    file: {
                                      type: "file",
                                      attrs: {
                                        label: "File",
                                      },
                                    },
                                    incomeFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Income File",
                                      },
                                    },
                                    affiliateFile: {
                                      type: "file",
                                      attrs: {
                                        label: "Affiliate File",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Tải lên",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const dataTable = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const { files: result } = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "integrationTiktokShop",
                                    "import",
                                    {
                                      marketId: market._id,
                                      xShopId: data.xShopId,
                                      warehouseId: data.warehouseId || "@any",
                                      productItemsLocked: `${data.productItemsLocked || "@any"}`,
                                    },
                                    data
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                const fileResult = result.find((f) => f.id === "file");
                                const incomeFileResult = result.find((f) => f.id === "incomeFile");
                                let lines = [];
                                if (fileResult) {
                                  lines = lines.concat([
                                    "File:",
                                    `Tạo mới thành công ${fileResult.createdOrderIds.length} đơn: ${fileResult.createdOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.createdIndexes.join(",")}`,
                                    "",
                                    `Cập nhật thành công ${fileResult.updatedOrderIds.length} đơn: ${fileResult.updatedOrderIds.join(",")}`,
                                    `Tương ứng với các hàng ${fileResult.updatedIndexes.join(",")}`,
                                    "",
                                    `Các hàng bị lỗi ${fileResult.errorIndexes.join(",")}`,
                                  ]);
                                }
                                if (incomeFileResult) {
                                  lines = lines.concat(["", "", "Income File: Cập nhật thành công"]);
                                }
                                form.success.value = lines.join("<br/>");
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            importExportedItems: market.tmdt &&
              userManager.checkRole(["kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  icon: "mdi-upload",
                  text: "Cập nhật xuất kho",
                },
                target: {
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    attrs: {
                      width: "60%",
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật xuất kho",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    warehouseId: {
                                      type: "XAutocomplete",
                                      attrs: {
                                        label: "Kho",
                                        "item-value": "_id",
                                        "item-text": "name",
                                        required: true,
                                        xOptions: {
                                          content: {
                                            async itemsLoader() {
                                              const { items } = await coreApiClient.call("warehouses", "findAll", {
                                                payload: JSON.stringify({
                                                  limit: -1,
                                                  filters: [
                                                    {
                                                      key: "countryId",
                                                      operator: "equal_to",
                                                      value: market.countryId,
                                                    },
                                                  ],
                                                }),
                                              });
                                              return items;
                                            },
                                          },
                                        },
                                      },
                                    },
                                    file: {
                                      type: "file",
                                      attrs: {
                                        label: "File",
                                        required: true,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Tải lên",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const dataTable = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "orders",
                                    "importExportedItems",
                                    {
                                      warehouseId: data.warehouseId,
                                    },
                                    data
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                const lines = [
                                  `Import thành công ${result.affectedOrderIds.length} đơn: ${result.affectedOrderIds.join(", ")}`,
                                  `Tương ứng với các hàng ${result.affectedIndexes.join(", ")}`,
                                  "",
                                  `${result.notAffectedIndexes.length} hàng không tìm thấy ${result.notAffectedIndexes.join(",")}`,
                                ];
                                if (result.extraParams) {
                                  const extraParams = JSON.parse(result.extraParams);
                                  if (extraParams && extraParams.exportedProducts) {
                                    const rowsHtml = extraParams.exportedProducts.map(
                                      (exportProduct) => `
                                    <tr>
                                      <td>${exportProduct.product.sku}</td>
                                      <td>${exportProduct.count}</td>
                                    </tr>`
                                    );

                                    const tableHtml = `
<table>
  <thead>
    <tr>
      <th>SKU</th>
      <th>SL</th>
    </tr>
  </thead>
  <tbody>
    ${rowsHtml}
  </tbody>
</table>`;
                                    lines.push(tableHtml);
                                  }
                                }
                                form.success.value = lines.join("<br/>");
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },

            batchReplaceProductItems: userManager.checkRole(["kt", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-file-replace",
                text: "Thay thế sản phẩm",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Thay thế sản phẩm",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "orders",
                                  "batchReplaceProductItems",
                                  {
                                    marketId: market._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          multiSelectActionButtons: {
            setH: !market.tmdt &&
              userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
                attrs: {
                  color: "default",
                  dark: false,
                },
                content: {
                  text: "Huỷ đơn hàng",
                  icon: "mdi-close",
                },
                target: {
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Huỷ đơn hàng",
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    customerRefusedStatus: {
                                      type: "select",
                                      attrs: {
                                        label: "Lý do hủy đơn",
                                        items: makeSelectOptions(orderCustomerRefusedStatuses),
                                        required: true,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const dataTable = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              const ids = dataTable.model.value.map((item) => item._id);
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "orders",
                                    "updateMany",
                                    {},
                                    {
                                      ids,
                                      data: {
                                        status: "h",
                                        customerRefusedStatus: data.customerRefusedStatus,
                                      },
                                      updateRequest: {
                                        scenario: "IndexSetStatusH",
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "vd", "vdl"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      content: {
                        xOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                    ext: {
                      subTitleMaker: "orderId",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              actionHistory: {
                content: {
                  icon: "mdi-format-list-bulleted-square",
                },
                target: {
                  tooltip: { content: { text: "Lịch sử tác động đơn hàng" } },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Lịch sử tác động đơn hàng",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.orderId;
                        },
                      },
                      contentIsNotDefaultComponent: true,
                      content: {
                        type: OrderHistory,
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
              wholesaleConfirm: userManager.checkRole(["kt"]) &&
                market.wholesale && {
                  attrs: {},
                  content: {
                    icon: "mdi-stamper",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Kế toán xác nhận",
                      },
                    },
                    dialog: {
                      handlers: {
                        initialize() {
                          this.options.content.buttons.save.states.enabled = new DataContainer(false);
                        },
                      },
                      content: {
                        toolbar: {
                          title: "Kế toán xác nhận",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.orderId;
                          },
                        },
                        content: {
                          type: "XForm",
                          makeAttrs(attrs, dialog) {
                            attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                            attrs.xContext = dialog;
                            attrs.xData = new DataContainer({});
                            attrs.xOptions = {
                              content: {
                                sections: {
                                  default: {
                                    fields: {
                                      files: {
                                        type: "file",
                                        attrs: {
                                          multiple: true,
                                          label: "Hóa đơn",
                                        },
                                      },
                                      exchangeRate: {
                                        type: "number",
                                        attrs: {
                                          label: "Tỷ giá",
                                          "prepend-icon": "mdi-currency-usd",
                                          suffix: `VNĐ / ${market.currency}`,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            };
                            return attrs;
                          },
                          attrs: {},
                        },
                        buttons: {
                          save: {
                            content: {
                              text: "Xác nhận",
                            },
                            states: {},
                            on: {
                              async xClick({ self }) {
                                const dialog = self.context();
                                const btn = dialog.context();
                                const { item, dataTable } = btn.context();
                                const form = dialog.contentContainer.value;
                                const data = form.getData();
                                console.log(data);
                                self.loading.value = true;
                                const result = await dataTable.execute(
                                  async () => {
                                    return await coreApiClient.call(
                                      "orders",
                                      "wholesaleConfirm",
                                      {
                                        id: item._id,
                                        exchangeRate: data.exchangeRate,
                                      },
                                      data,
                                      true
                                    );
                                  },
                                  undefined,
                                  {
                                    disableLoading: true,
                                    willRefresh: true,
                                  }
                                );
                                self.loading.value = false;
                                if (result) {
                                  dialog.hide();
                                }
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              wholesaleBillImages: userManager.checkRole(["kt"]) &&
                market.wholesale && {
                  attrs: {},
                  content: {
                    icon: "mdi-list-box-outline",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Bill xác nhận",
                      },
                    },
                    dialog: {
                      content: {
                        toolbar: {
                          title: "Bill xác nhận",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.orderId;
                          },
                        },
                        content: {
                          type: WholesaleBillImagesVue,
                          makeAttrs(attrs, dialog) {
                            attrs.item = dialog.context().context().item;
                            return attrs;
                          },
                          attrs: {},
                        },
                      },
                    },
                  },
                },
              pricing: userManager.checkRole(["kt"]) &&
                market.tmdt && {
                  attrs: {},
                  content: {
                    icon: "mdi-cash-multiple",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Giá bán",
                      },
                    },
                    dialog: {
                      attrs: {
                        width: "80%",
                      },
                      content: {
                        toolbar: {
                          title: "Giá bán",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.orderId;
                          },
                        },
                        content: {
                          type: OrderPricingVue,
                          makeAttrs(attrs, dialog) {
                            const { item } = dialog.context().context();
                            attrs.item = item;
                            return attrs;
                          },
                          attrs: {},
                        },
                      },
                    },
                  },
                },
              productRowItems: userManager.checkRole(["kt"]) &&
                market.tmdt && {
                  attrs: {},
                  content: {
                    icon: "mdi-list-box-outline",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Danh sách sản phẩm TMDT",
                      },
                    },
                    dialog: {
                      attrs: {
                        width: "80%",
                      },
                      content: {
                        toolbar: {
                          title: "Danh sách sản phẩm TMDT",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.orderId;
                          },
                        },
                        content: {
                          type: ProductRowItemsTable,
                          makeAttrs(attrs, dialog) {
                            const { item } = dialog.context().context();
                            attrs.item = item;
                            return attrs;
                          },
                          attrs: {},
                        },
                      },
                    },
                  },
                },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("orders", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              orderId: {
                attrs: {
                  readonly: true,
                },
              },
              productItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Danh sách sản phẩm",
                  xOptions: {
                    content: {
                      itemLabel: (item, i) => (item.product && item.product.sku) || `Sản phẩm ${i + 1}`,
                      itemProperties: {
                        product: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Sản phẩm",
                            "item-value": "_id",
                            "item-text": "sku",
                            "return-object": true,
                            clearable: true,
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("products", "findAll", {
                                    payload: JSON.stringify({
                                      search,
                                      limit,
                                      filters: [
                                        {
                                          key: "countryId",
                                          operator: "equal_to",
                                          value: market.countryId,
                                        },
                                        {
                                          key: "_id",
                                          operator: "equal_to",
                                          value: value && value._id,
                                        },
                                      ],
                                    }),
                                  });
                                  return items;
                                },
                              },
                            },
                          },
                        },
                        count: {
                          type: "integer",
                          attrs: {
                            label: "Số lượng",
                            required: true,
                          },
                        },
                        wholesaleTotalPrice: {
                          type: "number",
                          attrs: {
                            label: "Tổng tiền trước discount",
                          },
                          ext: {
                            condition() {
                              return market.wholesale;
                            },
                          },
                        },
                        wholesaleDiscountPercentage: {
                          type: "number",
                          attrs: {
                            label: "% Discount",
                          },
                          ext: {
                            condition() {
                              return market.wholesale;
                            },
                          },
                        },
                        wholesaleReceivedAmount: {
                          type: "number",
                          attrs: {
                            label: "Số tiền thực nhận",
                          },
                          ext: {
                            condition() {
                              return market.wholesale;
                            },
                          },
                        },
                        wholesaleRole: {
                          type: "select",
                          attrs: {
                            label: "Loại",
                            items: makeSelectOptions(productComboProductItemRoles),
                          },
                          ext: {
                            condition() {
                              return market.wholesale;
                            },
                          },
                        },
                        wholesaleProductGroupId: {
                          type: "XAutocomplete",
                          attrs: {
                            label: "Nhóm sản phẩm chính",
                            required: true,
                            "item-value": "_id",
                            "item-text": "sku",
                            "item-disabled": "_",
                            xOptions: {
                              content: {
                                async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("productGroups", "findAll", {
                                    payload: JSON.stringify({
                                      limit,
                                      search,
                                      filters: [
                                        {
                                          key: "_id",
                                          operator: "in",
                                          value,
                                        },
                                      ],
                                    }),
                                  });
                                  return displayItemsWithSku(items);
                                },
                              },
                            },
                          },
                          ext: {
                            condition() {
                              return market.wholesale;
                            },
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: market.wholesale ? 2 : 6,
                          },
                        },
                      },
                    },
                  },
                },
                ext: {
                  colLength: market.wholesale ? 12 : 6,
                },
              },
              productCombo: {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo sản phẩm",
                  "item-value": "_id",
                  "item-text": "name",
                  "return-object": true,
                  clearable: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value: value && value._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  emptyValue: null,
                },
              },
              product: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  "return-object": true,
                  clearable: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value: value && value._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  emptyValue: null,
                },
              },
              products: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm chính",
                  "item-value": "_id",
                  "item-text": "sku",
                  "return-object": true,
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value: value?.map((it) => it._id),
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return market.tmdt;
                  },
                },
              },
              count: {
                type: "integer",
                attrs: {
                  label: "Số lượng",
                  required: true,
                },
              },
              customerCode: {
                attrs: {
                  label: "Mã KH",
                },
              },
              customerName: {
                attrs: {
                  label: "Tên",
                },
              },
              customerEmail: {
                type: "email",
                attrs: {
                  label: "Email",
                },
              },
              customerPhone: {
                attrs: {
                  label: "SĐT",
                },
              },
              customerMessage: {
                type: "textLarge",
                attrs: {
                  label: "Lời nhắn",
                },
              },
              customerState: {
                attrs: {
                  label: "Tỉnh / TP",
                },
              },
              customerDistrict: {
                attrs: {
                  label: "Quận / Huyện",
                },
              },
              customerWard: {
                attrs: {
                  label: "Phường / Xã",
                },
              },
              customerAddress: {
                attrs: {
                  label: "Địa chỉ",
                },
              },
              customerPostalCode: {
                attrs: {
                  label: "Postal Code",
                },
              },
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(orderStatuses),
                },
                ext: {
                  defaultValue: "kt",
                },
              },
              telesaleStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái telesale",
                  items: makeSelectOptions(orderTelesaleStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              telesaleNote: {
                type: "textLarge",
                attrs: {
                  label: "Ghi chú telesale",
                },
              },
              cancelReason: {
                type: "textLarge",
                attrs: {
                  label: "Lý do khách hủy",
                },
              },
              additionalOrderNeed: {
                type: "boolean",
                attrs: {
                  label: "Khách đặt thêm đơn",
                },
              },
              notAnswerCallCount: {
                type: "integer",
                attrs: {
                  label: "Số lần không nghe máy",
                },
              },
              customerRefusedStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái hủy đơn",
                  items: makeSelectOptions(orderCustomerRefusedStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              dtStatus: {
                type: "select",
                attrs: {
                  label: "Lý do hủy đơn trùng",
                  items: makeSelectOptions(orderDtStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              locationStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái địa chỉ",
                  items: makeSelectOptions(orderLocationStatuses),
                },
                ext: {
                  defaultValue: "",
                },
              },
              locationNote: {
                type: "textLarge",
                attrs: {
                  label: "Ghi chú địa chỉ",
                },
              },
              pickUpStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái Pickup",
                  items: makeSelectOptions(orderPickUpStatuses),
                },
              },
              dataSource: {
                type: "select",
                attrs: {
                  label: "Nguồn data",
                  items: makeSelectOptions(orderDataSources),
                },
                ext: {
                  condition() {
                    return market.code.includes("-telesale");
                  },
                },
              },
              shippingStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái giao hàng",
                  items: makeSelectOptions(orderShippingStatuses),
                },
              },
              shippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              shippingCompanyAccountId: userManager.checkRole(["kt", "vdl", "vd"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Account ĐVVC",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanyAccounts", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              telesaleUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Telesale",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              locationUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Check địa chỉ",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              processUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User Process",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktUserId: {
                type: "XAutocomplete",
                attrs: {
                  label: "User MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktTeamId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Team",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              mktDepartmentId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              invalidStatus: {
                attrs: {
                  label: "TT Trùng đơn",
                },
              },
              transportTrackingNumber: {
                attrs: {
                  label: "Tracking Number",
                },
              },
              codCost: {
                type: "number",
                attrs: {
                  label: "Phí thu hộ COD",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
              },
              shippingCost: {
                type: "number",
                attrs: {
                  label: "Tổng phí ship",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
              },
              dxkTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày xuất kho",
                },
              },
              chatPageId: {
                attrs: {
                  label: "Chat Page ID",
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              customerIpCountryInvalid: {
                type: "boolean",
                attrs: {
                  label: "IP khác quốc gia",
                },
              },
              customerIpCoincide: {
                type: "boolean",
                attrs: {
                  label: "IP trùng so với tất cả các đơn hàng",
                },
              },
              customerIpCoincideRecent: {
                type: "boolean",
                attrs: {
                  label: "IP trùng gần đây",
                },
              },
              createdTime: {
                type: "XTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
              },
              productItemsLocked: {
                type: "boolean",
                attrs: {
                  label: "Khóa sản phẩm",
                },
              },
              wholesaleTotalPrice: {
                type: "number",
                attrs: {
                  label: "Tổng giá trước discount",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleDiscountPercentage: {
                type: "number",
                attrs: {
                  label: "Discount",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleReceivedAmount: {
                type: "number",
                attrs: {
                  label: "Số tiền thực nhận",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleShippingCost: {
                type: "number",
                attrs: {
                  label: "Phí ship",
                  "prepend-icon": "mdi-currency-usd",
                  suffix: market.currency,
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleOrderId: {
                attrs: {
                  label: "Mã đơn sỉ",
                },
              },
              wholesalePaidTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày thanh toán",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleExpectedDeliverTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày giao hàng",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleConfirmed: {
                type: "boolean",
                attrs: {
                  label: "Xác nhận",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleBankAccountName: {
                attrs: {
                  label: "Tên tài khoản",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleBankAccountNumber: {
                attrs: {
                  label: "Số tài khoản",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              wholesaleBankName: {
                attrs: {
                  label: "Ngân hàng",
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
              mktPlatformId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Nền tảng MKT",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return market.wholesale;
                  },
                },
              },
            },
            editForm: userManager.checkRole(["admin", "kt", "vd", "vdl"]) && {
              content: {
                colLength: 3,
                fieldNames: [
                  "orderId",
                  "count",
                  "productItems",
                  "productCombo",
                  "product",
                  "products",
                  "customerCode",
                  "customerName",
                  "customerEmail",
                  "customerPhone",
                  "customerMessage",
                  "customerState",
                  "customerDistrict",
                  "customerWard",
                  "customerAddress",
                  "customerPostalCode",
                  "status",
                  "telesaleStatus",
                  "telesaleNote",
                  "cancelReason",
                  "notAnswerCallCount",
                  "additionalOrderNeed",
                  "customerRefusedStatus",
                  "dtStatus",
                  "locationStatus",
                  "locationNote",
                  "pickUpStatus",
                  "dataSource",
                  "shippingStatus",
                  "shippingCompanyId",
                  "shippingCompanyAccountId",
                  "telesaleUserId",
                  "locationUserId",
                  "processUserId",
                  "mktUserId",
                  "mktTeamId",
                  "mktDepartmentId",
                  "invalidStatus",
                  "transportTrackingNumber",
                  "codCost",
                  "shippingCost",
                  "dxkTime",
                  "chatPageId",
                  "warehouseId",
                  "customerIpCountryInvalid",
                  "customerIpCoincide",
                  "customerIpCoincideRecent",
                  "createdTime",
                  "productItemsLocked",
                  "wholesaleShippingCost",
                  "wholesaleOrderId",
                  "wholesalePaidTime",
                  "wholesaleExpectedDeliverTime",
                  "wholesaleConfirmed",
                  "wholesaleBankAccountName",
                  "wholesaleBankAccountNumber",
                  "wholesaleBankName",
                  "mktPlatformId",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "orderId",
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    const setting = await coreApiClient.call("setting", "get");
    this.tableOptions = this.makeTableOptions(this.market, setting);
  },
});
